<template>
    <div @click.prevent="$emit('input', !value)" class="tw-cursor-pointer tw-py-2 tw-px-4 hover:tw-bg-gray-100 tw-text-sm tw-leading-relaxed tw-font-bold tw-rounded tw-transition-colors tw-duration-200 tw-inline-block">
        <div class="tw-flex tw-items-center" v-if="value">
            <slot name="on">
                {{ $t('global.actions.show_less') }}
            </slot>
            <k-icon name="chevron_up" class="tw-ml-1 tw-pt-px" />
        </div>
        <div class="tw-flex tw-items-center" v-else>
            <slot name="off">
                {{ $t('calculator.view_detailed_taxes_and_levies') }}
            </slot>
            <k-icon name="chevron_down" class="tw-ml-1 tw-pt-px" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
};
</script>
