import formatNumber from "./formatNumber";

const renderChartTooltip = (monthly, from, to = null, value, positive = true) => {
    return `
  <div class="gog-tooltip">
    <style>
      div.google-visualization-tooltip {
        pointer-events: none;
        user-select: none;
        background: none;
        border: none;
        overflow: visible;
        box-shadow: none;
        z-index: 4;
        position: absolute!important;
        top: 0.375rem!important;
        left: 0.375rem!important;
        -webkit-animation-duration: 250ms;
        animation-duration: 250ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay: 250ms;
        animation-delay: 250ms;
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
      }
      .gog-tooltip {
        padding: 0.75rem 1.5rem;
        width: 16rem;
        background-color: #1a202c;
        border-radius: 0.5rem;
      }
      .gog-tooltip__subtitle-wrapper {
        display: flex;
        align-items: flex-start;
      }
      .gog-tooltip__subtitle {
        color: #a0aec0;
        font-size: 0.75rem;
        font-weight: bold;
      }
      .gog-tooltip__subtitle--positive {
        color: #3CB46F;
      }
      .gog-tooltip__subtitle--negative {
        color: #FF6A66;
      }
      .gog-tooltip__subtitle-icon {
        color: #a0aec0;
        stroke: currentColor;
        height: 1rem;
        margin-top: 0.375rem;
        margin-right: 0.25rem;
        transform:  rotate(-90deg);
      }
      .gog-tooltip__subtitle-icon--positive {
        color: #3CB46F;
      }
      .gog-tooltip__subtitle-icon--negative {
        color: #FF6A66;
      }
      .gog-tooltip__title {
        color: #f7fafc;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 0.25rem;
      }
      .gog-tooltip__value {
        color: #f7fafc;
        font-size: 1.75rem;
        font-weight: bold;
        margin-top: 0.25rem;
      }
      .gog-tooltip__value--monthly {
        font-size: 1.125rem;
      }
      .gog-tooltip__value--positive {
        color: #3CB46F;
      }
      .gog-tooltip__value--negative {
        color: #FF6A66;
      }
      .gog-tooltip__euro {
      }
      .gog-tooltip__monthly {
        text-transform: lowercase;
      }
      @-webkit-keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
        }

        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }

      @keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
        }

        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
    </style>
    <div class="gog-tooltip__subtitle-wrapper">
      ${
          from
              ? `<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="gog-tooltip__subtitle-icon ${
                    positive ? "gog-tooltip__subtitle-icon--positive" : "gog-tooltip__subtitle-icon--negative"
                }">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path>
          </svg>
          <p class="gog-tooltip__subtitle ${positive ? "gog-tooltip__subtitle--positive" : "gog-tooltip__subtitle--negative"}">${from}<p>`
              : ""
      }
    </div>
    <p class="gog-tooltip__title">${to}<p>
    <p class="gog-tooltip__value ${positive ? "gog-tooltip__value--positive" : "gog-tooltip__value--negative"}">
      ${formatNumber(value * 12)}
      <span class="gog-tooltip__euro">&euro;</span>
    <p>
    <p class="gog-tooltip__value gog-tooltip__value--monthly ${positive ? "gog-tooltip__value--positive" : "gog-tooltip__value--negative"}">
      (${formatNumber(value)}
      <span class="gog-tooltip__euro">&euro;</span>
      <span class="gog-tooltip__monthly">${monthly})</span>
    <p>
  </div>
  `;
};

export default renderChartTooltip;
